












import UserForm from '@/components/auth/UserForm.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { User } from '@/model/user';

export default Vue.extend({
  components: {
    UserForm,
    LoadingIndicator
  },
  computed: {
    ...mapStores(useUsersStore),
    savingUser(): boolean {
      return this.usersStore.user.saving;
    },
    user(): User {
      return this.usersStore.user.entity;
    },
    isFetchingUser(): boolean {
      return this.usersStore.user.fetching;
    }
  },
  methods: {
    async saveUser(userDto) {
      await this.usersStore.edit(userDto);
      await this.usersStore.getUser(this.user.id);
    }
  }
});
